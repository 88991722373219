import * as Sentry from '@sentry/nuxt'

const router = useRouter()
const { public: { sentry, appVersion } } = useRuntimeConfig()

Sentry.init({
  dsn: sentry.dsn,
  release: appVersion,
  tracesSampleRate: 0.5,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
})
